<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :afterIcon="
              [
                {
                  name: 'search',
                  click: true,
                  callbackName: 'searchSafetyGear',
                },
                {
                  name: 'close',
                  click: true,
                  callbackName: 'removeSafetyGear',
                },
              ]
            "
            :editable="editable"
            :readonly="true"
            label="보호구"
            name="safetyGearName"
            v-model="searchParam.safetyGearName"
            @searchSafetyGear="searchSafetyGear"
            @removeSafetyGear="removeSafetyGear"
          >
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="보호구 재고현황"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @table-data-change="tableDataChange"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'stockCount'">
          <font v-if="props.row['stockCount'] < 0" color="#C10015">
            {{props.row[col.name]}}
          </font>
          <font v-else color="##245ded">
            {{ props.row[col.name]}}
          </font>
        </template>
        <template v-if="col.name === 'realCount'"> 
          <font v-if="props.row['realCount'] < 0" color="#C10015">
            {{props.row[col.name]}}
          </font>
          <font v-else color="##245ded">
            {{ props.row[col.name]}}
          </font>
        </template>
      </template>
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="updateUrl"
            :param="grid.data"
            mappingType="PUT"
            label="재고수정" 
            icon="save" 
            @beforeAction="saveMst"
            @btnCallback="saveCallback"/>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-gear-stock-adjust',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'supplyVendorName',
          //   field: 'supplyVendorName',
          //   label: '공급업체',
          //   style: 'width:110px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'stockCount',
            field: 'stockCount',
            label: '현재수량',
            style: 'width:120px',
            type: 'custom',
            align: 'right',
            sortable: true,
          },
          {
            name: 'mediateCount',
            field: 'mediateCount',
            label: '조정수량',
            style: 'width:120px',
            type: 'number',
            align: 'right',
            sortable: true,
          },
          {
            name: 'realCount',
            field: 'realCount',
            label: '실사수량<br>(현재 + 조정)',
            style: 'width:120px',
            align: 'right',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            type: 'text',
            sortable: true,
          },
        ],
        data: [],
      },
      typeItems: [
        { code: 'Y', codeName: '전체' },
        { code: 'N', codeName: '일별' },
      ],
      searchParam: {
        plantCd: null,
        safetyGearId: '',
        safetyGearName: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateUrl: 'transactionConfig.sop.sas.stock.adjust.url',
      saveType: 'PUT',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.stock.adjust.url;
      this.updateUrl = transactionConfig.sop.sas.stock.adjust.url;
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveMst() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    searchSafetyGear() {
      this.popupOptions.title = "보호구 선택"; // 보호구 선택
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"./safetyGearPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSafetyGearPopup;
    },
    closeSafetyGearPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.safetyGearId = data[0].safetyGearId;
        this.searchParam.safetyGearName = data[0].safetyGearName;
      }
    },
    removeSafetyGear() {
      this.searchParam.safetyGearId = '';
      this.searchParam.safetyGearName = '';
    },
    tableDataChange(props) {
      this.$set(props.row, 'realCount', Number(props.row['stockCount']) + Number(props.row['mediateCount']))
    }
  },
};
</script>
